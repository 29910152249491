<template>
    <div v-if="$isMobile()">
 
        <swiper :options="swiperOptionsBanner" class="w-100">
            <swiper-slide v-for="item in banners" :key="index" class="rounded swiper-shadow">
                <img style="height:200px;object-fit: cover;" class="rounded w-100" :src="item.banner_picture" />
            </swiper-slide> 
        </swiper>
        <!-- END BANNER -->

        <div class="text-white text-center mt-5 mb-5 px-2">
            <h2 class="text-white font-weight-bolder">Mongrela Shelter Association</h2>
            <p class="text-justify">
                Join the Mongrela Shelter community and be a vital part of helping our furry friends in need around you. Even a small contribution from you can make a significant difference in their lives. Be a part of our family, and together, let's make a positive impact. Your support matters. Join us, and let's create a better world for our beloved pets.            </p>
        </div>

        <div class=" px-2">
            <router-link to="/shelter/list">
                <div class="text-white d-flex align-items-center p-1 mt-1" style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;">
                    <div class="h4 text-white mb-0">Shelter List</div>
                    <i class="bx bx-chevron-right ml-auto" style="font-size: 20px;"></i>
                </div>
            </router-link>
        </div>

        <!-- LEARN MORE -->
        <div class="px-2 mt-3 mb-2">
            <swiper :options="swiperOptions" class="w-100">
                <swiper-slide v-for="(data, index) in ads" :key="index" class="rounded swiper-shadow">
                    <img style="height:200px;object-fit: cover;" class="rounded w-100" :src="data.ads_picture" />
                </swiper-slide>
                <div slot="button-next" class="swiper-button-next d-flex"><i
                        class="m-auto bx bx-chevron-right text-dark m-auto" style="font-size:28px"></i></div>
                <div slot="button-prev" class="swiper-button-prev d-flex"><i
                        class="m-auto bx bx-chevron-left text-dark m-auto" style="font-size:28px"></i></div>
            </swiper>
        </div>
        <!-- END LEARN MORE -->
 
    </div>

    <div v-else class="container-lg py-5">
        <img src="@/static/img_assets/Artboard 1 1.png" class="w-100 rounded" />

        <div class="display-5 fw-bolder text-white mt-3 mb-1">
            Mongrella Shelter Association
        </div>
        <div class="text-white h4 fw-normal">
            Join the Mongrela Shelter community and be a vital part of helping our furry friends in need around you. Even a small contribution from you can make a significant difference in their lives. Be a part of our family, and together, let's make a positive impact. Your support matters. Join us, and let's create a better world for our beloved pets.
        </div>

        <div class="my-3" v-if="active_shelter">
            <div class="text-white fw-bolder h2">Mongrela Associated Shelter</div>
            <div style="height: 1px;background-color: #FAB13E;" class="w-100 mb-3"></div>
            <div class="row" style="gap:10px 0">
                <div class="col-lg-4">
                    <div class="text-white d-flex flex-column" style="gap:10px;overflow-y: auto;height: 550px;">
                        <div v-for="(item, idx) in shelter" :key="item.shelter_id" 
                            :class="`shelter-card ${active_shelter.shelter_id == item.shelter_id ? 'active' : ''} d-flex p-2 align-items-center`" 
                            style="gap:20px;cursor: pointer;" @click="current_shelter_idx = idx"
                        >
                            <img :src="item.shelter_picture" style="aspect-ratio: 1/1;width:80px;background: #FFFFFF;object-fit: cover;" class="rounded-circle" />
                            <div>
                                <div class="fw-bolder h3 mb-0 text-white">{{ item.shelter_name }}</div>
                                <div class="fw-normal h4 mb-0 text-white">{{ item.shelter_location }}</div>
                            </div>
                            <i class="ti ti-chevron-right ms-auto fw-bolder" style="font-size: 30px;"></i>
                        </div> 
                    </div>
                </div>
                <div class="col-lg-8">
                    <div class="h-100 w-100 p-3 d-flex flex-column position-relative" style="border: 2px solid #FAB13E;">
                        <div class="position-absolute d-flex align-items-center flex-wrap" style="gap:10px;right: 20px;top: 20px;">
                            <router-link :to="`/shelter/profile/${active_shelter.shelter_id}`">
                                <div class="text-white d-flex align-items-center" style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;padding:.5em 1em">
                                    View Shelter
                                </div>
                            </router-link>
                            <router-link :to="`/chats/${active_shelter.user_id}`" v-if="user.user_id != active_shelter.user_id">
                                <div class="text-white d-flex align-items-center" style="gap:10px;background-color: #1B628C;border: 2px solid #FFAD32;border-radius: 20px;padding:.5em 1em">
                                    <i class="bx bxs-message-dots" style="font-size: 18px;cursor: pointer;color: #FFAD32;"></i>
                                </div>
                            </router-link>
                        </div>
                        <div class="d-flex flex-column align-items-center m-auto">
                            <img :src="active_shelter.shelter_picture" style="aspect-ratio:1/1;width:150px;background: #FFFFFF;object-fit: cover;" class=" " />
                            <div class="h2 fw-bolder text-white my-1">{{active_shelter.shelter_name}}</div>
                            <div style="max-width: 500px;" class="text-center text-white">
                                {{ active_shelter.shelter_details }}
                            </div>
                            <div class="h3 fw-bolder text-white my-1">{{ active_shelter.shelter_location }}</div>
                            <div class="h3 fw-bolder text-white mb-1">{{ active_shelter.shelter_phone }}</div>
                        </div>

                        <div v-if="active_shelter.gallery.length > 0">
                            <div class="h3 fw-bolder text-white my-1">
                                Our Galery
                            </div>

                            <swiper :options="swiperOptionsGallery" class="w-100">
                                <swiper-slide v-for="item in active_shelter.gallery" :key="item.shg_id" class="rounded swiper-shadow" style="width: fit-content">
                                    <img @click="changeImage(item)" :src="item.shg_picture" style="aspect-ratio:1/1;width:140px;background: #FFFFFF;border-radius: 10px;object-fit: cover;" class="" />
                                </swiper-slide>
                            </swiper>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="text-center h1 fw-bolder mt-4" v-else style="color:#F9B43B">
            No Shelter Associated Yet
        </div>

        <div class="position-relative container-lg mt-4 px-0">
            <img src="@/static/img_assets/image.png" class="position-absolute w-100 h-100" />
            <div class="ml-2 px-1 py-4 position-relative" style="z-index: 1;">
                <div class="h1 text-center text-white mb-3">Notice Boards</div>
                <div>
                    <div class="h1 text-white">Adoption Center</div>
                    <hr />
                    <div class="notice-boards" ref="adoption" style="gap:30px;">
                        <div v-for="item in adoption_list">
                            <div  class="p-1"
                                style="border-radius: 10px;border:2px solid #FFAD32;width:400px;background-color: rgba(0,0,0,.1);">
                                <div class="d-flex align-items-center py-1 px-1" style="gap:10px 20px">
                                    <div>
                                        <img :src="item.sha_picture ? item.sha_picture : default_img" class="rounded-circle"
                                            style="height:100px;aspect-ratio:1/1;background: white;" />
                                    </div>
                                    <div>
                                        <h3 class="text-white h5 mb-0">{{item.sha_name}}</h3>
                                        <h3 class="text-white h4 text-truncate" style="margin: .2em 0 !important;">
                                            {{item.sha_species}}
                                        </h3>
                                        <h3 class="text-white h5">{{item.sha_age}} Months, {{item.sha_gender}}</h3>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-center" style="gap:10px">
                                    <router-link :to="`/shelter/adopt/detail/${item.sha_id}`" class="btn px-1" style="
                                        border-radius: 15px;
                                        border: 2px solid #FFAD32;
                                        background: #005071;
                                        color: white;
                                    ">
                                        Know More
                                    </router-link> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <div @click="scrollLeft('adoption')" class="d-flex align-items-center h2 text-white" style="gap:10px;cursor: pointer;">
                            <i class="ti ti-chevron-left" style="font-size: 40px;"></i>
                            <div>Previous</div>
                        </div>
                        <div @click="scrollRight('adoption')" class="d-flex align-items-center h2 text-white" style="gap:10px;cursor: pointer;">
                            <div>Next</div>
                            <i class="ti ti-chevron-right" style="font-size: 40px;"></i>
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <div class="h1 text-white">Volunteering</div>
                    <hr />
                    <div class="notice-boards" ref="volunteer" style="gap:30px;">
                        <div v-for="item in volunteer_list">
                            <div class="p-1"
                                style="border-radius: 10px;border:2px solid #FFAD32;width:400px !important;background-color: rgba(0,0,0,.1);"
                                >
                                <img v-if="item.shv_picture" class="w-100 rounded mb-1"
                                    style="aspect-ratio:4/1;object-fit: cover;background:white" :src="item.shv_picture" />

                                <div class="d-flex align-items-center" style="gap:10px 20px">
                                    <div class="h3 text-white mb-0">{{ item.shv_title }}, {{ item.shv_cause }}</div>
                                </div>

                                <div class="text-white h5" style="margin-top: .5em;">
                                    {{ moment(item.shv_date).format('dddd, DD MMMM YYYY') }}, {{ item.shv_time }}
                                </div>
                                <div class="text-white h5" style="margin-top: .5em;">{{ item.shv_location }}</div>

                                <div class="text-white h5 font-weight-bolder" style="margin-top: 1em;">Details: </div>

                                <p class="text-white" style="margin-top: .5em;">
                                    {{ item.shv_details }}
                                </p>

                                <div class="d-flex align-items-center justify-content-center" style="gap:10px">
                                    <router-link :to="`/shelter/volunteer/${item.shelter_id}`" class="btn px-1" style="
                                        border-radius: 15px;
                                        border: 2px solid #FFAD32;
                                        background: #005071;
                                        color: white;
                                    ">
                                        Know More
                                    </router-link> 
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <div @click="scrollLeft('volunteer')" class="d-flex align-items-center h2 text-white" style="gap:10px;cursor: pointer;">
                            <i class="ti ti-chevron-left" style="font-size: 40px;"></i>
                            <div>Previous</div>
                        </div>
                        <div @click="scrollRight('volunteer')" class="d-flex align-items-center h2 text-white" style="gap:10px;cursor: pointer;">
                            <div>Next</div>
                            <i class="ti ti-chevron-right" style="font-size: 40px;"></i>
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <div class="h1 text-white">Donation</div>
                    <hr />
                    <div class="notice-boards" ref="donation" style="gap:30px;">
                        <div v-for="item in donation_list">
                            <div class="p-1" style="border-radius: 10px;border:2px solid #FFAD32;width: 400px;">
                                <img v-if="item.shd_picture" class="w-100 rounded mb-1" style="max-height: 100px;object-fit: cover;background-color: white;"
                                    :src="item.shd_picture" />
                                
                                <div class="d-flex align-items-center justify-content-between" style="gap:10px">
                                    <div class="h3 text-white mb-0">{{ item.shd_title }}</div> 
                                </div>

                                <div class="d-flex align-items-center" style="gap:10px">
                                    <div class="my-1 progress progress-bar-warning border flex-fill">
                                        <div class="progress-bar " role="progressbar" aria-valuenow="65" aria-valuemin="65"
                                            aria-valuemax="100" :style="`width: ${((item.shd_raised ? item.shd_raised : 0) / item.shd_fund_pledge) * 100}%`"></div>
                                    </div>
                                    <h5 class="mb-0 text-white">{{((item.shd_raised ? item.shd_raised : 0) / item.shd_fund_pledge) * 100}}%</h5>
                                </div>

                                <div class="row" style="gap:10px 0">
                                    <div class="col-6">
                                        <div class="text-white h5 mb-0">Pledge: $ $ {{item.shd_fund_pledge}},00</div>
                                    </div>
                                    <div class="col-6">
                                        <div class="text-white h5 mb-0">Raised: $ {{(item.shd_raised ? item.shd_raised : 0)}},00</div>
                                    </div>
                                    <div class="col-6">
                                        <div class="text-white h5 mb-0">Donators: {{item.donators.length}}</div>
                                    </div>
                                </div>

                                <div class="text-white h5 font-weight-bolder" style="margin-top: 1em;">Details: </div>

                                <p class="text-white" style="margin-top: .5em;">
                                    {{ item.shd_details }}
                                </p>

                                <div class="d-flex align-items-center justify-content-center" style="gap:10px">
                                    <router-link :to="`/shelter/donate/${item.shelter_id}`" class="btn px-1" style="
                                        border-radius: 15px;
                                        border: 2px solid #FFAD32;
                                        background: #005071;
                                        color: white;
                                    ">
                                        Know More
                                    </router-link> 
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between mt-1">
                        <div @click="scrollLeft('donation')" class="d-flex align-items-center h2 text-white" style="gap:10px;cursor: pointer;">
                            <i class="ti ti-chevron-left" style="font-size: 40px;"></i>
                            <div>Previous</div>
                        </div>
                        <div @click="scrollRight('donation')" class="d-flex align-items-center h2 text-white" style="gap:10px;cursor: pointer;">
                            <div>Next</div>
                            <i class="ti ti-chevron-right" style="font-size: 40px;"></i>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="mt-5 position-relative" style="z-index: 1;">
            <div class="d-flex flex-column  align-items-center justify-content-center" style="gap:10px">
                <img src="@/static/logo - Copy.png" style="max-width:300px" />
                <div class="fw-bolder text-white display-3 mb-0">Be a Part Of Our Packs</div>
                <div class="text-white h3">Pet Goes Social</div>
            </div>
        </div>

        <image-viewer-vue 
            v-if="imageViewerFlag" 
            @closeImageViewer="imageViewerFlag = false" 
            :imgUrlList="imgUrlList"
            :index="currentIndex"
            :title="title"
            :closable=true
            :cyclical=false>
        </image-viewer-vue>
    </div>

</template>

<script>

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

import {BCarousel, BCarouselSlide} from 'bootstrap-vue'
import moment from 'moment'

import store from '@/store'
import shelter from '@/store/stores/shelter'

export default {
    components: {
        Swiper, SwiperSlide,
        BCarousel, BCarouselSlide
    },
    computed: {
        user(){
            return store.state.auth.USER
        },
        banners(){
            return store.state.banners.BANNERS || []
        },
        ads(){
            return store.state.banners.ADS || []
        },
        adoption_list(){
            return store.state.shelter.ADOPTION_LIST
        },
        donation_list(){
            return store.state.shelter.DONATION_LIST
        },
        volunteer_list(){
            return store.state.shelter.VOLUNTEER_LIST
        },
        shelter(){
            return store.state.shelter.SHELTER_LIST
        },
        active_shelter(){
            if(this.shelter.length > 0){
                return this.shelter[this.current_shelter_idx]
            } else return null
        }
    },
    mounted(){
        store.dispatch('banners/GetBanners')
        store.dispatch('banners/GetAds')
        if(!this.$isMobile()){
            store.dispatch('shelter/GetShelterList')
            store.dispatch('shelter/GetAdoptionList')
            store.dispatch('shelter/GetDonationList')
            store.dispatch('shelter/GetVolunteerList')
        }
    },
    methods: {
        changeImage(data,index){ 
            this.imageViewerFlag = true
            this.imgUrlList = [data.shg_picture]
        },
        scrollLeft(item) {
            let scrollContainer = this.$refs.adoption;
            if(item == 'volunteer'){
                scrollContainer = this.$refs.volunteer;
            }
            else if(item == 'donation'){
                scrollContainer = this.$refs.donation;
            }
            scrollContainer.scrollBy({ left: -400, behavior: 'smooth' });
        },
        scrollRight(item) { 
            let scrollContainer = this.$refs.adoption;
            if(item == 'volunteer'){
                scrollContainer = this.$refs.volunteer;
            }
            else if(item == 'donation'){
                scrollContainer = this.$refs.donation;
            } 
            scrollContainer.scrollBy({ left: 400, behavior: 'smooth' });
        },
    },
    data() {
        return {
            current_shelter_idx : 0,
            learn_more_banner: [
                { url: 'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-cover-template-design-7fbcea6de5ed45af0716f61106314548_screen.jpg?ts=1622085870' },
                { url: 'https://d1csarkz8obe9u.cloudfront.net/posterpreviews/pet-shop-banner-design-template-4c29604da3e3601ec0a5d464e2cbeb4b_screen.jpg?ts=1621925071' }
            ],
            swiperOptions: {
                slidesPerView: 1,
                spaceBetween: 10,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                autoplay: {
                    delay: 3000,
                },
            },
            swiperOptionsBanner: {
                slidesPerView: 1,
                spaceBetween: 10, 
                autoplay: {
                    delay: 3000,
                },
            },
            swiperOptionsGallery: {
                slidesPerView: 'auto',
                spaceBetween: 10, 
                autoplay: {
                    delay: 3000,
                },
            },
            moment,
            imageViewerFlag: false,
            title: 'Image Preview',
            imgUrlList: []
        }
    }
}

</script>

<style scoped>
    .shelter-card.active {
        background-color: #A5A670;
        border-radius: 50px;
    }
</style>

<style> 
.input-group.search span {
    border-right: unset;
    border-color: #FFAD32;
    border-radius: 10px 0 0 10px;
    font-size: 24px;
    color: #FFAD32;
    background-color: transparent;
}

.input-group.search input {
    margin-left: 0;
    padding-left: 0;
    border-left: unset;
    border-radius: 0 10px 10px 0;
    border-color: #FFAD32;
    height: 40px;
    color: white;
    background-color: transparent !important;
}

.swiper-button-prev,
.swiper-button-next {
    --swiper-theme-color: transparent;
    background: #ffffff;
    box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.2);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0 !important;
}

.swiper-button-prev::after,
.swiper-button-next::after {
    content: unset !important;
}
</style> 